<template>
  <div>

    <!-- Filters -->
    <sample-request-list-filters
      :sample-status-filter.sync="sampleStatusFilter"
      :sample-status-options="sampleStatusOptions"
      :brands-filter.sync="brandsFilter"
      :brands-options="brandsOptions"
    />

    <b-row>
      <b-col class="d-flex justify-content-end mb-2">
        <b-button
          pill
          size="sm"
          class="mx-1"
          :variant="viewType == 'cards' ? 'primary': 'outline-primary'"
          @click="viewType = 'cards'"
        >
          <feather-icon
            icon="FolderIcon"
            size="16"
            class="mx-1"
          />
        </b-button>
        <b-button
          pill
          size="sm"
          class="mx-1"
          :variant="viewType == 'listing' ? 'primary': 'outline-primary'"
          @click="viewType = 'listing'"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="mx-1"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h1>Sample Requests</h1>

        <b-table
          v-show="viewType=='listing'"
          ref="refSampleRequestListTable"
          class="position-relative"
          :items="fetchStyleSampleRequests"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          primary-key="id"
          show-empty
          empty-text=""
        >

          <!-- Column: Image -->
          <template #cell(image)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.image"
                  :to="{ name: 'apps-sample-requests-edit', params: { id: data.item.id } }"
                />
              </template>
            </b-media>
          </template>

          <!-- Column: Style -->
          <template #cell(style)="data">
            <b-link
              :to="{ name: 'apps-styles-edit',
                     params: { id: data.item.style_id },
                     hash: '#7',
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              <div class="text-nowrap">
                <h6 class="table-cell-title my-0">
                  {{ data.item.name }}
                </h6>
                <h6 class="text-muted my-0">
                  {{ data.item.ref }}
                </h6>
              </div>
            </b-link>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
            </b-dropdown>
          </template>
        </b-table>
        <b-container
          v-show="viewType=='cards'"
        >
          <b-row>
            <b-col
              v-for="sample in rawSampleRequests"
              :key="sample.id"
              class="sample-card-box"
            >
              <sample-request-card
                :sample="sample"
                :set-sample-request-status="setSampleRequestStatus"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BCol, BContainer, BDropdown, BLink, BMedia, BRow, BTable,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'
import store from '@/store'

import sampleRequestStoreModule from '../sampleRequestStoreModule'
import SampleRequestCard from './SampleRequestCard.vue'
import SampleRequestListFilters from './SampleRequestListFilters.vue'

export default {
  components: {
    SampleRequestCard,
    SampleRequestListFilters,

    BAvatar,
    BButton,
    BCol,
    BContainer,
    BDropdown,
    BLink,
    BMedia,
    BRow,
    BTable,
  },
  data() {
    return {}
  },
  setup(props, { root }) {
    const APP_STORE_MODULE_NAME = 'app-sample-request'

    // Register module
    if (!root.$store.hasModule(APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(APP_STORE_MODULE_NAME, sampleRequestStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(APP_STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'

    const sampleStatusOptions = computed(() => {
      const options = root.$store.getters['app-sample-request/sampleStatusOptions']
      return options
    })

    const brandsOptions = computed(() => {
      const options = root.$store.getters['app-sample-request/brandsOptions']
      return options
    })

    const refSampleRequestListTable = ref(null)

    const tableColumns = !isBrand ? [
      { key: 'image', sortable: false },
      { key: 'brand', sortable: false },
      { key: 'brand', sortable: false },
      { key: 'style', sortable: false },
      { key: 'sample_type', sortable: false },
      { key: 'sample_status', label: 'Status', sortable: false },
      { key: 'deadline', sortable: true },
      { key: 'estimate', sortable: true },
    ] : [
      { key: 'image', sortable: false },
      { key: 'brand', sortable: false },
      { key: 'style', sortable: false },
      { key: 'sample_type', sortable: false },
      { key: 'sample_status', label: 'Status', sortable: false },
      { key: 'deadline', sortable: true },
      { key: 'estimate', sortable: true },
    ]
    const hash = decodeURI(router.currentRoute.hash)
    const sampleStatusFilter = ref(hash ? hash.slice(1) : 'Requested')
    const brandsFilter = ref('')
    const sortBy = ref('')
    const sortDesc = ref(false)
    const viewType = ref('listing')
    const sampleRequestId = ref(0)
    const sampleRequestData = ref({})
    const rawSampleRequests = ref([])

    const refreshData = () => {
      refSampleRequestListTable.value.refresh()
    }

    watch([sampleStatusFilter], () => {
      router.replace({
        name: 'apps-sample-requests-list',
        hash: `#${sampleStatusFilter.value}`,
      })
      refreshData()
    })

    watch([brandsFilter], () => {
      refreshData()
    })

    const optionsInit = () => {
      root.$store.dispatch('app-sample-request/fetchFormOptions')
    }

    const fetchStyleSampleRequests = (ctx, callback) => {
      const queryParams = {
        sample_status: sampleStatusFilter.value,
      }

      if (brandsFilter.value) {
        queryParams.style__brand = brandsFilter.value
      }

      if (sortBy.value) {
        queryParams.ordering = sortDesc.value ? sortBy.value : `-${sortBy.value}`
      }
      store.dispatch('app-sample-request/fetchStyleSampleRequests', queryParams)
        .then(response => {
          rawSampleRequests.value = response.data
          callback(response.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            callback([])
          }
        })
    }

    const setSampleRequestStatus = (id, status) => {
      store.dispatch('app-sample-request/updateSampleRequest',
        { id, sample_status: status })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status modified',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          fetchStyleSampleRequests()
        })
        .catch(({ response }) => {
          const { data } = response
          if (data.sample_status) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: data.sample_status[0],
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    onMounted(optionsInit)

    return {
      sampleStatusFilter,
      brandsFilter,
      sortBy,
      sortDesc,
      viewType,

      tableColumns,
      refSampleRequestListTable,
      sampleRequestId,
      sampleRequestData,

      fetchStyleSampleRequests,
      setSampleRequestStatus,
      rawSampleRequests,
      refreshData,
      sampleStatusOptions,
      brandsOptions,
    }
  },
}
</script>
<style lang="css">
.sample-card-box {
  flex: 260px 0;
}
</style>
